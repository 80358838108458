import React, { useState } from "react";

export const PlayerImage = ({ teamName = '', playerName = '', height = '40px', width = '40px' }) => {
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);

  let teamX = teamName;

  if (typeof teamName !== 'string') {
    if (Array.isArray(teamName) && teamName.length > 0) {
      teamX = teamName[currentTeamIndex];
    } else {
      teamX = '';
    }
  }

  const fallbackImageUrl = `${process.env.CDN_ENDPOINT}/player-placeholder-dark.svg`;
  const teamlogo = `${process.env.CDN_ENDPOINT}/IPL+Team+Images/${teamX.replace(/ /g, '+')}/${playerName.replace(/ /g, "_")}.png`;

  const handleError = (e) => {
    if (Array.isArray(teamName) && currentTeamIndex < teamName.length - 1) {
      // Try loading the next team's image
      setCurrentTeamIndex(currentTeamIndex + 1);
    } else {
      e.target.src = fallbackImageUrl;
    }
  };

  function handleLoad(event) {
    const img = event.target;
    if (img.naturalHeight > img.naturalWidth) {
      img.style.maxHeight = `${height}`;
    } else {
      // For images where width is greater
    }
  }

  return (
    <div> 
      <img src={teamlogo} onError={handleError} alt={teamX} width={`${width}`} className={`w-[${width}]`} onLoad={handleLoad} />
    </div>
  );
};
export default PlayerImage;
